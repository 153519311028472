import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';

import {
  Pump,
  Pond,
  Valve,
  ValveConnect,
  PipePlaceholder,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowCustom,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Info,
  Alarms,
  MultiInfo,
  Statistic,
  PLCStateRouter,
  PLCColorRouter,
  MobileStatTable,
  LoadingIndicator,
} from 'isno/lib/components/displays';

import {
  AlarmReset,
  ToggleControl,
  VFDPumpControl,
  DrainValveControl,
  MultiSetpointControl,
} from 'isno/lib/components/controls';

import PLC from 'isno/lib/static/images/PLC.png';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Building Data
  buildingTemp: 'building_temperature',
  // Water Flow Source
  cityTankMode: 'city_tank_mode',
  // Water Line Data
  waterFlowRate: 'water_flow_rate',
  line3WaterFlow: 'line_3_water_flow',
  line2WaterFlow: 'line_2_water_flow',
  // Air Line Data
  line2AirFlow: 'line_2_air_flow',
  line2AirTemp: 'line_2_air_temperature',
  line3AirFlow: 'line_3_air_flow',
  line3AirTemp: 'line_3_air_temperature',
  dischargeAirPressure: 'discharge_air_pressure',
  // Pump Data
  speed: 'speed',
  dischargePressure: 'discharge_water_pressure',
  dischargeTemperature: 'discharge_water_temperature',
  amps: 'current',
  hours: 'run_time',
  // Pump Alarms
  warning: 'warning',
  fault: 'fault',
  lowAmps: 'low_amps',
  masterAlarm: 'master_alarm',
  lowSuctionPressure: 'low_suction_pressure',
  emergencyStop: 'emergency_stop',
  // Valve Control
  openClose: 'open_close',
  dischargeWaterPressure: 'discharge_water_pressure',
  dischargeWaterTemperature: 'discharge_water_temperature',
  suctionWaterPressure: 'suction_water_pressure',
  // Valve Alarms
  // v1OpeningError: 'V1_opening_error',
  // v1ClosingError: 'V1_closing_error',
};

function BaseAreaScreen(props) {
  const theme = useTheme();
  // const language = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(9), // 6
        props.fetchLocation(2), // 13
        props.fetchLocation(1), // 14
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) {
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchPLCs();
    props.fetchLocation(9); // 6
    props.fetchLocation(2); // 13
    props.fetchLocation(1); // 14
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null;
    };
  }, []);

  const pumpMainStatusKey = {
    0: 'READY',
    1: 'RUN',
    2: 'Switch',
    3: 'High Temp',
    4: 'Low Level',
    5: 'Run Fail',
    6: 'Disabled',
    7: 'Comm',
    8: 'Local',
    9: 'Fault',
    10: 'Suction',
    11: 'LEAD',
    12: 'E-Stop',
  };

  const pumpMainStatusColorKey = {
    0: `${theme.onGreen}`,
    1: `${theme.onGreen}`,
    2: `${theme.alarm}`,
    3: `${theme.alarm}`,
    4: `${theme.alarm}`,
    5: `${theme.alarm}`,
    6: `${theme.alarm}`,
    7: `${theme.alarm}`,
    8: `${theme.alarm}`,
    9: `${theme.alarm}`,
    10: `${theme.alarm}`,
    11: `${theme.onGreen}`,
    12: `${theme.alarm}`,
  };

  // const locationEquipment3 = props.locations?.locations?.[3]?.equipment;
  const locationEquipment6 = props.locations?.locations?.[9]?.equipment; // 6
  // const locationEquipment11 = props.locations?.locations?.[11]?.equipment;
  const locationEquipment13 = props.locations?.locations?.[2]?.equipment; // 13
  const locationEquipment14 = props.locations?.locations?.[1]?.equipment; // 14
  if (!locationEquipment6 || !locationEquipment13 || !locationEquipment14) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }
  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        {/* used 800px below instead of passing ${theme.mobileBreakpoint} */}
        <BasicRow styles="min-width: fit-content; min-height: fit-content; display: flex; flex-direction: row; flex-wrap: nowrap; @media only screen and (max-width: 800px) {flex-direction: column;}">
          <LabeledContainer styles2="display: flex; justify-content: space-between; flex-direction: column; height: calc(100% - 29px);" height="fit-content" flex="1" border label="Old River">
            <InfoControlRow>
              {/* <MultiInfo
                title="Diversion Flow"
                subtitles={['System 1', 'System 2']}
                statistics={[
                  locationEquipment6?.Other?.ioValues?.system1_water_flow?.value,
                  locationEquipment6?.Other?.ioValues?.system2_water_flow?.value,
                ]}
                ids={[
                  locationEquipment6?.Other?.ioValues?.system1_water_flow?.id,
                  locationEquipment6?.Other?.ioValues?.system2_water_flow?.id,
                ]}
                labels={[
                  locationEquipment6?.Other?.ioValues?.system1_water_flow?.ioValueType?.units,
                  locationEquipment6?.Other?.ioValues?.system2_water_flow?.ioValueType?.units,
                ]}
              /> */}
              <MultiInfo
                title="Total Old River Flow"
                subtitles={['Electronic', 'Mechanical']}
                statistics={[
                  locationEquipment6.Other.ioValues.total_electronic_water_flow?.value,
                  locationEquipment6.Other.ioValues.total_mechanical_water_flow?.value,
                ]}
                labels={[
                  locationEquipment6.Other.ioValues.total_electronic_water_flow?.ioValueType?.units,
                  locationEquipment6.Other.ioValues.total_mechanical_water_flow?.ioValueType?.units,
                ]}
                // alertText={(() => {
                //   if (locationEquipment3?.Other?.ioValues?.pemi_river_low_flow?.value) {
                //     return 'Low Flow';
                //   } else if (locationEquipment3?.Other?.ioValues?.pemi_river_low_flow_warning?.value) {
                //     return 'Low Flow Warning';
                //   } else {
                //     return null;
                //   }
                // })()}
                // alertTop="35px"
                // alertLeft={locationEquipment3?.Other?.ioValues?.pemi_river_low_flow_warning?.value ? '-12px' : '23px'}
              />
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[33])} // plcId 8: OldRiver
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[33], theme)}
              />
              <ToggleControl
                title="Instrument Air"
                ioData={locationEquipment6.IA}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                }}
                status={locationEquipment6?.IA?.status ? 'Enabled' : 'Disabled'}
                statusColor={locationEquipment6?.IA?.status ? theme.onGreen : theme.alarm}
                setIOValue={props.setIOValue}
                button1Text="Enable"
                button2Text="Disable"
                readValues={[1, 0]}
                writeValues={[1, 0]}
                dot
                buttonWidth="65px"
              />
              {/* <Info
                title="Instrument Air"
                statistic={locationEquipment6?.IA?.status ? 'Enabled' : 'Disabled'}
                color={locationEquipment6?.IA?.status ? theme.onGreen : theme.alarm}
                label=""
                alertText={locationEquipment6?.IA?.ioValues?.low_air_pressure?.value ? 'Low Air Pressure' : null}
                alertTop="54px"
                alertLeft="-8px"
              /> */}
              <MultiSetpointControl
                title="CV1 PID"
                pGainLabel="Back P-Gain SP"
                iGainLabel="Back I-Gain SP"
                pGainLabel2="Hill P-Gain SP"
                iGainLabel2="Hill I-Gain SP"
                settings
                pidData={locationEquipment6?.['CV1 PID']}
                IOValueIDs={[
                  `${locationEquipment6?.['CV1 PID']?.ioValues?.back_auto_setpoint?.id}`,
                  `${locationEquipment6?.['CV1 PID']?.ioValues?.hill_auto_setpoint?.id}`,
                  `${locationEquipment6?.['CV1 PID']?.ioValues?.manual_setpoint?.id}`,
                ]}
                statistics={[
                  `${locationEquipment6?.['CV1 PID']?.ioValues?.back_auto_setpoint.value}`,
                  `${locationEquipment6?.['CV1 PID']?.ioValues?.hill_auto_setpoint.value}`,
                  `${locationEquipment6?.['CV1 PID']?.ioValues?.manual_setpoint.value}`,
                ]}
                labels={['Back Auto SP:', 'Hill Auto SP:', 'Manual Setpoint:']}
                units={[
                  locationEquipment6?.['CV1 PID']?.ioValues?.back_auto_setpoint?.ioValueType?.units,
                  locationEquipment6?.['CV1 PID']?.ioValues?.hill_auto_setpoint?.ioValueType?.units,
                  locationEquipment6?.['CV1 PID']?.ioValues?.manual_setpoint?.ioValueType?.units,
                ]}
                setIOValue={props.setIOValue}
                IOValueKeys={{
                  autoManual: 'auto_manual',
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                  pressureMode: 'hill_back',
                  pGainSetpoint: 'back_pgain_setpoint',
                  iGainSetpoint: 'back_igain_setpoint',
                  pGainSetpoint2: 'hill_pgain_setpoint',
                  iGainSetpoint2: 'hill_igain_setpoint',
                }}
                iGainPrecision={2}
                pGainPrecision={2}
                readValues={[0, 1]}
                writeValues={[0, 1]}
                buttons
                inputNumWidth="72px"
              />
              <MultiSetpointControl
                title="CV2 PID"
                pGainLabel="Back P-Gain SP"
                iGainLabel="Back I-Gain SP"
                pGainLabel2="Hill P-Gain SP"
                iGainLabel2="Hill I-Gain SP"
                settings
                pidData={locationEquipment6?.['CV2 PID']}
                IOValueIDs={[
                  `${locationEquipment6?.['CV2 PID']?.ioValues?.back_auto_setpoint?.id}`,
                  `${locationEquipment6?.['CV2 PID']?.ioValues?.hill_auto_setpoint?.id}`,
                  `${locationEquipment6?.['CV2 PID']?.ioValues?.manual_setpoint?.id}`,
                ]}
                statistics={[
                  `${locationEquipment6?.['CV2 PID']?.ioValues?.back_auto_setpoint.value}`,
                  `${locationEquipment6?.['CV2 PID']?.ioValues?.hill_auto_setpoint.value}`,
                  `${locationEquipment6?.['CV2 PID']?.ioValues?.manual_setpoint.value}`,
                ]}
                labels={['Back Auto SP:', 'Hill Auto SP:', 'Manual Setpoint:']}
                units={[
                  locationEquipment6?.['CV2 PID']?.ioValues?.back_auto_setpoint?.ioValueType?.units,
                  locationEquipment6?.['CV2 PID']?.ioValues?.hill_auto_setpoint?.ioValueType?.units,
                  locationEquipment6?.['CV2 PID']?.ioValues?.manual_setpoint?.ioValueType?.units,
                ]}
                setIOValue={props.setIOValue}
                IOValueKeys={{
                  autoManual: 'auto_manual',
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                  pressureMode: 'hill_back',
                  pGainSetpoint: 'back_pgain_setpoint',
                  iGainSetpoint: 'back_igain_setpoint',
                  pGainSetpoint2: 'hill_pgain_setpoint',
                  iGainSetpoint2: 'hill_igain_setpoint',
                }}
                iGainPrecision={2}
                pGainPrecision={2}
                readValues={[0, 1]}
                writeValues={[0, 1]}
                buttons
                inputNumWidth="72px"
              />
              <MultiSetpointControl
                title="CV3 Bypass"
                pidData={locationEquipment6?.CV3}
                IOValueIDs={[
                  `${locationEquipment6?.CV3?.ioValues?.open_setpoint?.id}`,
                  `${locationEquipment6?.CV3?.ioValues?.close_setpoint?.id}`,
                ]}
                statistics={[
                  `${locationEquipment6?.CV3?.ioValues?.open_setpoint.value}`,
                  `${locationEquipment6?.CV3?.ioValues?.close_setpoint.value}`,
                ]}
                button1Text="Open"
                button2Text="Close"
                labels={['Open SP:', 'Close SP:']}
                units={[
                  locationEquipment6?.CV3?.ioValues?.open_setpoint?.ioValueType?.units,
                  locationEquipment6?.CV3?.ioValues?.close_setpoint?.ioValueType?.units,
                ]}
                setIOValue={props.setIOValue}
                IOValueKeys={{
                  autoManual: locationEquipment6?.CV3?.ioValues?.close_open?.value === 1 ? 'close' : 'open',
                  autoMode: 'close_open',
                  manualMode: 'close_open',
                }}
                readValues={[1, 0]}
                writeValues={[1, 1]}
                buttons
                inputNumWidth="72px"
              />
              <MultiSetpointControl
                title="CV4 Bypass"
                pidData={locationEquipment6?.CV4}
                IOValueIDs={[
                  `${locationEquipment6?.CV4?.ioValues?.open_setpoint?.id}`,
                  `${locationEquipment6?.CV4?.ioValues?.close_setpoint?.id}`,
                ]}
                statistics={[
                  `${locationEquipment6?.CV4?.ioValues?.open_setpoint.value}`,
                  `${locationEquipment6?.CV4?.ioValues?.close_setpoint.value}`,
                ]}
                button1Text="Open"
                button2Text="Close"
                labels={['Open SP:', 'Close SP:']}
                units={[
                  locationEquipment6?.CV4?.ioValues?.open_setpoint?.ioValueType?.units,
                  locationEquipment6?.CV4?.ioValues?.close_setpoint?.ioValueType?.units,
                ]}
                setIOValue={props.setIOValue}
                IOValueKeys={{
                  autoManual: locationEquipment6?.CV4?.ioValues?.close_open?.value === 1 ? 'close' : 'open',
                  autoMode: 'close_open',
                  manualMode: 'close_open',
                }}
                readValues={[1, 0]}
                writeValues={[1, 1]}
                buttons
                inputNumWidth="72px"
              />
              <DrainValveControl
                title="System 1 Drain Valve"
                valveData={locationEquipment6.DV1}
                openIOValueID={locationEquipment6.DV1?.ioValues?.open?.id}
                closeIOValueID={locationEquipment6.DV1?.ioValues?.close?.id}
                setIOValue={props.setIOValue}
                IOValueKeys={{
                  closeOpen: 'open_close',
                  autoManual: 'auto_manual',
                }}
                readValues={[1, 0, 0, 1]}
                writeValues={[1, 0, 0, 1]}
              />
              <DrainValveControl
                title="System 2 Drain Valve"
                valveData={locationEquipment6.DV2}
                openIOValueID={locationEquipment6.DV2?.ioValues?.open?.id}
                closeIOValueID={locationEquipment6.DV2?.ioValues?.close?.id}
                setIOValue={props.setIOValue}
                IOValueKeys={{
                  closeOpen: 'open_close',
                  autoManual: 'auto_manual',
                }}
                readValues={[1, 0, 0, 1]}
                writeValues={[1, 0, 0, 1]}
              />
              <ToggleControl
                title="System Mode"
                ioData={locationEquipment6.Other}
                IOValueKeys={{
                  start: 'system1_mode_disable_enable',
                  stop: 'system2_mode_disable_enable',
                  started: 'system1_mode_disable_enable',
                  stopped: 'system2_mode_disable_enable',
                }}
                setIOValue={props.setIOValue}
                button1Text="System 1"
                button2Text="System 2"
                readValues={[1, 1]}
                writeValues={[1, 1]}
                dot
                buttonWidth="74px"
              />
              <ToggleControl
                title="Dual Mode"
                ioData={locationEquipment6.Other}
                IOValueKeys={{
                  start: 'dual_system_mode_disable_enable',
                  stop: 'dual_system_mode_disable_enable',
                  started: 'dual_system_mode_disable_enable',
                  stopped: 'dual_system_mode_disable_enable',
                }}
                setIOValue={props.setIOValue}
                button1Text="Enable"
                button2Text="Disable"
                readValues={[1, 0]}
                writeValues={[1, 0]}
                dot
                buttonWidth="68px"
              />
              <Alarms
                title="General Alarms"
                ioValues={locationEquipment6?.Other?.ioValues}
              />
              <AlarmReset
                title="System Reset"
                buttonText="Reset"
                alarmData={locationEquipment6?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarm: 'master_alarm',
                  masterAlarmReset: 'alarm_reset',
                }}
                resetWriteValue={1}
              />
              <MobileStatTable
                title="Water System Metrics"
                stats={
                [
                  {
                    description: 'Sys. 1 Discharge Water PSI',
                    stat: locationEquipment6?.Other?.ioValues.sys1_discharge_water_pressure?.value,
                    label: locationEquipment6?.Other?.ioValues.sys1_discharge_water_pressure?.ioValueType?.units,
                  },
                  {
                    description: 'Sys. 2 Discharge Water PSI',
                    stat: locationEquipment6?.Other?.ioValues.sys2_discharge_water_pressure?.value,
                    label: locationEquipment6?.Other?.ioValues.sys2_discharge_water_pressure?.ioValueType?.units,
                  },
                  {
                    description: 'Sys. 1 Backwater PSI',
                    stat: locationEquipment6?.Other?.ioValues.sys1_back_water_pressure?.value,
                    label: locationEquipment6?.Other?.ioValues.sys1_back_water_pressure?.ioValueType?.units,
                  },
                  {
                    description: 'Sys. 2 Backwater PSI',
                    stat: locationEquipment6?.Other?.ioValues.sys2_back_water_pressure?.value,
                    label: locationEquipment6?.Other?.ioValues.sys2_back_water_pressure?.ioValueType?.units,
                  },
                ]
              }
              />
            </InfoControlRow>
            <PumpsRowCustom height="216px">
              <div css={[styles.flexColumn]}>
                <div css={styles.flexColumn} />
                <ValveConnect
                  connectAbove
                  pipeAbove="right"
                  percent
                  percentTop="50px"
                  nameTop="7px"
                  nameLeft="50px"
                  valveStateColorKeys={{
                    1: theme.offRed,
                    0: theme.onGreen,
                  }}
                  valveStateKeys={{
                    1: 'Closed',
                    0: 'Opened',
                  }}
                  valveData={{
                    name: 'DV1',
                    state: locationEquipment6?.DV1?.ioValues?.open_close?.value,
                    id: `controlValve_${locationEquipment6?.DV1?.id}`,
                  }}
                />
              </div>
              <div css={[styles.flexColumn]}>
                <div css={styles.flexColumn} />
                <ValveConnect
                  connectAbove
                  pipeAbove="middle"
                  percent
                  percentTop="50px"
                  nameTop="7px"
                  nameLeft="50px"
                  valveStateColorKeys={{
                    0: theme.offRed,
                    1: theme.onGreen,
                  }}
                  valveData={{
                    name: 'CV3',
                    state: locationEquipment6?.CV3?.ioValues?.close_open?.value,
                    id: `controlValve_${locationEquipment6?.CV3?.id}`,
                  }}
                />
              </div>
              <div css={styles.flexColumn}>
                <div css={styles.flexColumn}>
                  <div css={css`height: 25px;`}>
                    <PipePlaceholder capBefore capLabel="System 1" capLabelTop="-50px" capLabelLeft="-25px" />
                  </div>
                  <div css={[css`width: 51px; display: flex; flex-direction: column; justify-content: center; align-items: flex-start;`, styles.hiddenMobile]}>
                    <div css={css`width: fit-content;`}>
                      <Statistic
                        label={locationEquipment6?.Other?.ioValues.sys1_discharge_water_pressure?.ioValueType?.units}
                        border
                        statistic={locationEquipment6?.Other?.ioValues.sys1_discharge_water_pressure?.value}
                        alertText={locationEquipment6?.Other?.ioValues.sys1_low_discharge_pressure?.value ? 'Low Pressure' : null}
                        alertTop="0px"
                        alertLeft="40px"
                      />
                    </div>
                    <div css={css`height: 4px; width: 51px;`}>
                      <PipePlaceholder />
                    </div>
                    <div css={css`width: fit-content;`}>
                      <Statistic
                        label={locationEquipment6?.Other?.ioValues.system1_electronic_water_flow?.ioValueType?.units}
                        border
                        statistic={locationEquipment6?.Other?.ioValues.system1_electronic_water_flow?.value}
                        floatLabel="(Electronic)"
                        floatLabelTop="2px"
                        floatLabelLeft="66px"
                      />
                    </div>
                    <div css={css`height: 4px; width: 51px;`}>
                      <PipePlaceholder />
                    </div>
                    <div css={css`width: fit-content;`}>
                      <Statistic
                        label={locationEquipment6?.Other?.ioValues.system1_mechanical_water_flow?.ioValueType?.units}
                        border
                        statistic={locationEquipment6?.Other?.ioValues.system1_mechanical_water_flow?.value}
                        floatLabel="(Mechanical)"
                        floatLabelTop="2px"
                        floatLabelLeft="66px"
                      />
                    </div>
                    <div css={css`height: 12px; width: 51px;`}>
                      <PipePlaceholder />
                    </div>
                  </div>
                </div>
                <ValveConnect
                  connectAbove
                  pipeAbove="left"
                  percent
                  percentTop="50px"
                  nameTop="7px"
                  nameLeft="50px"
                  binary={false}
                  valveStateColorKeys={{
                    0: theme.offRed,
                    100: theme.onGreen,
                  }}
                  valveData={{
                    name: 'CV1',
                    state: locationEquipment6?.['CV1 PID']?.ioValues?.manual_setpoint?.value,
                    id: `controlValve_${locationEquipment6?.['CV1 PID']?.id}`,
                  }}
                />
              </div>
              <PipePlaceholder hidden />
              <PipePlaceholder hidden />
              <div css={styles.flexColumn}>
                <div css={styles.flexColumn}>
                  <div css={css`height: 25px;`}>
                    <PipePlaceholder capBefore capLabel="System 2" capLabelTop="-50px" capLabelLeft="-25px" />
                  </div>
                  <div css={[css`width: 51px; display: flex; flex-direction: column; justify-content: center; align-items: center;`, styles.hiddenMobile]}>
                    <div css={css`width: fit-content;`}>
                      <Statistic
                        border
                        label={locationEquipment6?.Other?.ioValues.sys2_discharge_water_pressure?.ioValueType?.units}
                        statistic={locationEquipment6?.Other?.ioValues.sys2_discharge_water_pressure?.value}
                        alertText={locationEquipment6?.Other?.ioValues.sys2_low_discharge_pressure?.value ? 'Low Pressure' : null}
                        alertTop="0px"
                        alertLeft="40px"
                      />
                    </div>
                    <div css={css`height: 4px; width: 51px;`}>
                      <PipePlaceholder />
                    </div>
                    <div css={css`width: fit-content;`}>
                      <Statistic
                        label={locationEquipment6?.Other?.ioValues.system2_electronic_water_flow?.ioValueType?.units}
                        border
                        statistic={locationEquipment6?.Other?.ioValues.system2_electronic_water_flow?.value}
                        floatLabel="(Electronic)"
                        floatLabelTop="2px"
                        floatLabelLeft="66px"
                      />
                    </div>
                    <div css={css`height: 4px; width: 51px;`}>
                      <PipePlaceholder />
                    </div>
                    <div css={css`width: fit-content;`}>
                      <Statistic
                        label={locationEquipment6?.Other?.ioValues.system2_mechanical_water_flow?.ioValueType?.units}
                        border
                        statistic={locationEquipment6?.Other?.ioValues.system2_mechanical_water_flow?.value}
                        floatLabel="(Mechanical)"
                        floatLabelTop="2px"
                        floatLabelLeft="66px"
                      />
                    </div>
                    <div css={css`height: 12px; width: 51px;`}>
                      <PipePlaceholder />
                    </div>
                  </div>
                </div>
                <ValveConnect
                  connectAbove
                  pipeAbove="right"
                  reverseValve
                  percent
                  percentTop="50px"
                  nameTop="7px"
                  nameLeft="25px"
                  binary={false}
                  valveStateColorKeys={{
                    0: theme.offRed,
                    100: theme.onGreen,
                  }}
                  valveData={{
                    name: 'CV2',
                    state: locationEquipment6?.['CV2 PID']?.ioValues?.manual_setpoint?.value,
                    id: `controlValve_${locationEquipment6?.['CV2 PID']?.id}`,
                  }}
                />
              </div>
              <div css={styles.flexColumn}>
                <div css={styles.flexColumn} />
                <ValveConnect
                  connectAbove
                  pipeAbove="middle"
                  reverseValve
                  percent
                  percentTop="50px"
                  nameTop="7px"
                  nameLeft="25px"
                  valveStateColorKeys={{
                    0: theme.offRed,
                    1: theme.onGreen,
                  }}
                  valveData={{
                    name: 'CV4',
                    state: locationEquipment6?.CV4?.ioValues?.close_open?.value,
                    id: `controlValve_${locationEquipment6?.CV4?.id}`,
                  }}
                />
              </div>
              <div css={styles.flexColumn}>
                <div css={styles.flexColumn} />
                <ValveConnect
                  connectAbove
                  pipeAbove="left"
                  reverseValve
                  percent
                  percentTop="50px"
                  nameTop="7px"
                  nameLeft="25px"
                  valveStateColorKeys={{
                    1: theme.offRed,
                    0: theme.onGreen,
                  }}
                  valveStateKeys={{
                    1: 'Closed',
                    0: 'Opened',
                  }}
                  valveData={{
                    name: 'DV2',
                    state: locationEquipment6?.DV2?.ioValues?.open_close?.value,
                    id: `controlValve_${locationEquipment6?.DV2.id}`,
                  }}
                />
              </div>
            </PumpsRowCustom>
            <PumpsRowCustom height="135px">
              <PipePlaceholder />
              <PipePlaceholder />
              <div css={styles.flexRow}>
                <div css={css`width: 33px`}>
                  <Pump
                    pipeSizeAbove="medium"
                    controlPositionTop="-36px"
                    controlPositionLeft="-10px"
                    setIOValue={props.setIOValue}
                    pumpData={locationEquipment6?.P1}
                    otherData={locationEquipment6?.Other}
                    stateColorKey={{
                      0: `${theme.secondary}`,
                      1: `${theme.onGreen}`,
                      2: `${theme.warning}`,
                      3: `${theme.alarm}`,
                    }}
                    IOValueKeys={{
                      start: 'start',
                      stop: 'stop',
                      started: 'run_feedback',
                      stopped: 'run_feedback',
                    }}
                    alarms={[
                      locationEquipment6?.P1?.ioValues?.low_amps,
                      locationEquipment6?.P1?.ioValues?.master_alarm,
                    ]}
                    stats={[
                      {
                        stat: locationEquipment6?.P1?.ioValues?.[IOValueKeys.amps]?.value,
                        label: locationEquipment6?.P1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                      },
                      {
                        stat: locationEquipment6?.P1?.ioValues?.[IOValueKeys.hours]?.value,
                        label: locationEquipment6?.P1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                      },
                    ]}
                    statuses={[
                      // {
                      //   id: locationEquipment6.P1?.ioValues?.local_remote?.id,
                      //   status: locationEquipment6.P1?.ioValues?.local_remote?.value,
                      //   statusKey: {
                      //     0: 'Local',
                      //     1: 'Remote',
                      //   },
                      //   statusColorKey: {
                      //     0: theme.manual, // These aren't the "named" colors but I like them for this use case
                      //     1: theme.local,
                      //   },
                      // },
                    ]}
                  />
                </div>
                <div css={[css`width: 38px`, styles.hiddenMobile]}>
                  <PipePlaceholder horizontalAbove minWidth="0px" />
                </div>
                <div css={[styles.hiddenMobile]}>
                  <Statistic
                    label={locationEquipment6?.Other?.ioValues.sys1_back_water_pressure?.ioValueType?.units}
                    border
                    statistic={locationEquipment6?.Other?.ioValues.sys1_back_water_pressure?.value}
                    precision={1}
                  />
                </div>
                <div css={[styles.hiddenMobile]}>
                  <Statistic
                    label={locationEquipment6?.Other?.ioValues.sys1_water_temperature?.ioValueType?.units}
                    border
                    statistic={locationEquipment6?.Other?.ioValues.sys1_water_temperature?.value}
                    precision={1}
                  />
                </div>
                <PipePlaceholder horizontalAbove minWidth="0px" />
              </div>
              <ValveConnect
                // manual
                readOnly
                percent
                percentTop="22px"
                percentLeft="-5px"
                nameTop="-65px"
                nameLeft="7px"
                valveData={{ ...locationEquipment6?.CV6, name: 'CV6' }}
                horizontal="above"
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                  2: 'Moving',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.inTravel,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  moving: 'opened',
                }}
                alarms={[
                  locationEquipment6?.CV6?.ioValues?.not_closed,
                ]}
                readValues={[1, 1]}
                yellowImageKey="Moving"
              />
              <div css={styles.flexRow}>
                <div css={[styles.hiddenMobile]}>
                  <Statistic
                    label={locationEquipment6?.Other?.ioValues.sys2_back_water_pressure?.ioValueType?.units}
                    border
                    statistic={locationEquipment6?.Other?.ioValues.sys2_back_water_pressure?.value}
                  />
                </div>
                <div css={[styles.hiddenMobile]}>
                  <Statistic
                    label={locationEquipment6?.Other?.ioValues.sys2_water_temperature?.ioValueType?.units}
                    border
                    statistic={locationEquipment6?.Other?.ioValues.sys2_water_temperature?.value}
                  />
                </div>
                <PipePlaceholder horizontalAbove minWidth="18px" />
              </div>
              <div css={styles.flexRow}>
                <div css={css`width: 0px`}>
                  <PipePlaceholder horizontalAbove minWidth="18px" />
                </div>
                <Pump
                  pipeSizeAbove="medium"
                  controlPositionTop="-36px"
                  controlPositionLeft="-10px"
                  setIOValue={props.setIOValue}
                  pumpData={locationEquipment6.P2}
                  otherData={locationEquipment6.Other}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  IOValueKeys={{
                    start: 'start',
                    stop: 'stop',
                    started: 'run_feedback',
                    stopped: 'run_feedback',
                  }}
                  alarms={[
                    locationEquipment6?.P2?.ioValues?.low_amps,
                    locationEquipment6?.P2?.ioValues?.master_alarm,
                  ]}
                  stats={[
                    {
                      stat: locationEquipment6?.P2?.ioValues?.[IOValueKeys.amps]?.value,
                      label: locationEquipment6?.P2?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: locationEquipment6?.P2?.ioValues?.[IOValueKeys.hours]?.value,
                      label: locationEquipment6?.P2?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                  statuses={[
                    // {
                    //   id: locationEquipment6.P2?.ioValues?.local_remote?.id,
                    //   status: locationEquipment6.P2?.ioValues?.local_remote?.value,
                    //   statusKey: {
                    //     0: 'Local',
                    //     1: 'Remote',
                    //   },
                    //   statusColorKey: {
                    //     0: theme.manual, // These aren't the "named" colors but I like them for this use case
                    //     1: theme.local,
                    //   },
                    // },
                  ]}
                />
              </div>
              <PipePlaceholder />
              <PipePlaceholder />
            </PumpsRowCustom>
            <PumpsRowCustom height="65px">
              <Pond color={theme.pipeColors.water}>
                <Info
                  title="Well Level"
                  statistic={locationEquipment6?.Other?.ioValues?.well_level?.value}
                  precision={1}
                  label={locationEquipment6?.Other?.ioValues?.well_level?.ioValueType?.units}
                  alertText={locationEquipment6?.Other?.ioValues?.low_well_level?.value ? 'Low Well Level' : null}
                  alertTop="15px"
                  alertLeft="110px"
                />
              </Pond>
            </PumpsRowCustom>
          </LabeledContainer>
          <LabeledContainer height="fit-content" flex="3" border label="Boyle Brook">
            <InfoControlRow>
              {/* <div css={css`display: flex; flex-direction: column;`}> */}
              {/* <MultiInfo
                title="Diversion Flow"
                subtitles={['Diversion']}
                statistics={[locationEquipment13?.Other?.ioValues.discharge_water_flow?.value]}
                ids={[locationEquipment13?.Other?.ioValues.discharge_water_flow?.id]}
                labels={[locationEquipment13?.Other?.ioValues.discharge_water_flow?.ioValueType?.units]}
              /> */}
              <Info
                title="Brook Level"
                statistic={locationEquipment13?.Other?.ioValues.brook_water_flow?.value}
                label={locationEquipment13?.Other?.ioValues.brook_water_flow?.ioValueType?.units}
              />
              <Info
                title="Diversion Flow"
                statistic={locationEquipment13?.Other?.ioValues.discharge_water_flow?.value}
                label={locationEquipment13?.Other?.ioValues.discharge_water_flow?.ioValueType?.units}
              />
              {/* </div> */}
              {/* <div css={css`display: flex; flex-direction: column;`}> */}
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[1])} // plcId 1: annex -> not sure why this one
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[1], theme)}
              />
              <Info
                title="Instrument Air"
                statistic={locationEquipment14?.IA?.ioValues?.low_air_pressure?.value === 0 ? 'Enabled' : 'Low Pressure'}
                color={locationEquipment14?.IA?.ioValues?.low_air_pressure?.value === 0 ? theme.onGreen : theme.alarm}
                label=""
              />
              {/* </div> */}
              <AlarmReset
                title="System Reset"
                buttonText="Reset"
                alarmData={locationEquipment13?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarm: null,
                  masterAlarmReset: 'alarm_reset',
                }}
                resetWriteValue={1}
              />
              <VFDPumpControl
                title="Control Valve CV1 PID"
                autoPrecision={1}
                manPrecision={1}
                inputNumWidth="64px"
                pumpData={{ id: `controlValve_${locationEquipment13?.['CV1 PID']?.id}` }}
                pidData={locationEquipment13?.['CV1 PID']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoMode: 'manual_auto',
                  manualMode: 'manual_auto',
                  autoManual: 'manual_auto',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                }}
                readValues={[1, 0]}
                writeValues={[1, 0]}
              />
              <VFDPumpControl
                title="Discharge Valve DV1 PID"
                autoPrecision={1}
                manPrecision={1}
                inputNumWidth="64px"
                pumpData={{ id: `controlValve_${locationEquipment13?.['DV1 PID']?.id}` }}
                pidData={locationEquipment13?.['DV1 PID']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoMode: 'manual_auto',
                  manualMode: 'manual_auto',
                  autoManual: 'manual_auto',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                }}
                readValues={[1, 0]}
                writeValues={[1, 0]}
              />
            </InfoControlRow>
            <PumpsRowCustom height="45px" />
            <PumpsRowCustom height="160px">
              <PipePlaceholder capBefore capLabel="From Brook" capLabelTop="-25px" capLabelLeft="10px" horizontalBelow color={theme.pipeColors.water} size="large" minWidth="100px">
                <Statistic
                  alertText={locationEquipment13?.Other?.ioValues[IOValueKeys.lowSuctionPressure]?.value ? 'Low Pressure' : null}
                  alertTop="3px"
                  alertLeft="40px"
                  size="large"
                  label={locationEquipment13?.Other?.ioValues[IOValueKeys.suctionWaterPressure]?.ioValueType?.units}
                  border
                  statistic={locationEquipment13?.Other?.ioValues[IOValueKeys.suctionWaterPressure]?.value}
                />
              </PipePlaceholder>
              <Pump
                localRemote
                psi
                percent
                pipeAbove="right"
                pipeBelow="left"
                pipeSizeAbove="medium"
                pipeSizeBelow="large"
                controlWidth="135px"
                controlPositionTop="-44px"
                controlPositionLeft="-20px"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment13.P3}
                otherData={locationEquipment13.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  localRemote: 'local_remote',
                  local: 'local_remote',
                  remote: 'local_remote',
                  // drain: 'drain',
                  dischargePressure: 'back_water_pressure',
                // suctionPressure: 'suction_water_pressure',
                }}
                readValues={[0, 0, 0, 1]}
                writeValues={[1, 1, 0, 1]}
                alarms={[
                // props.equipment?.P3?.ioValues[IOValueKeys.warning],
                // props.equipment?.P3?.ioValues[IOValueKeys.fault],
                // props.equipment?.P3?.ioValues[IOValueKeys.lowAmps],
                // props.equipment?.P3?.ioValues[IOValueKeys.masterAlarm],
                // props.equipment?.Other?.ioValues[IOValueKeys.lowSuctionPressure],
                ]}
                stats={[
                  {
                    stat: locationEquipment13?.P3?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment13?.P3?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment13?.P3?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment13?.P3?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
                statuses={[
                  {
                    id: locationEquipment13.P3?.ioValues?.local_remote?.id,
                    status: locationEquipment13.P3?.ioValues?.local_remote?.value,
                    statusKey: {
                      0: 'Local',
                      1: 'Remote',
                    },
                    statusColorKey: {
                      0: theme.manual, // These aren't the "named" colors but I like them for this use case
                      1: theme.local,
                    },
                  },
                  // {
                  //   id: locationEquipment13.P3?.ioValues?.drain?.id,
                  //   status: locationEquipment13.P3?.ioValues?.drain?.value,
                  //   statusKey: {
                  //     0: '',
                  //     1: 'Draining',
                  //   },
                  //   statusColorKey: {
                  //     0: '', // These aren't the "named" colors but I like them for this use case
                  //     1: theme.auto,
                  //   },
                  // },
                ]}
              />
              <div css={css`display: flex; width: 10px; height: 100%;`}>
                <PipePlaceholder horizontalAbove minWidth="10px" />
              </div>
              <ValveConnect
                pipeAbove="middle"
                minWidth="65px"
                capAfter
                percent
                percentTop="50px"
                nameTop="7px"
                nameLeft="50px"
                binary={false}
                valveStateColorKeys={{
                  0: theme.offRed,
                  100: theme.onGreen,
                }}
                valveData={{
                  name: 'DV1',
                  state: locationEquipment13?.['DV1 PID']?.ioValues?.manual_setpoint?.value,
                  id: `controlValve_${locationEquipment13?.['DV1 PID']?.id}`,
                }}
              />
              <Valve
                top
                horizontal
                percent
                percentTop="55px"
                nameTop="25px"
                nameLeft="5px"
                binary={false}
                valveStateColorKeys={{
                  0: theme.offRed,
                  100: theme.onGreen,
                }}
                valveData={{
                  name: 'CV1',
                  state: locationEquipment13?.['CV1 PID']?.ioValues?.manual_setpoint?.value,
                  id: `controlValve_${locationEquipment13?.['CV1 PID']?.id}`,
                }}
              />
              <PipePlaceholder capAfter capLabel="To Hill" capLabelTop="-25px" capLabelLeft="-60px" horizontalAbove color={theme.pipeColors.water} minWidth="75px">
                <Statistic
                  label={locationEquipment13?.Other?.ioValues[IOValueKeys.dischargeWaterPressure]?.ioValueType?.units}
                  border
                  statistic={locationEquipment13?.Other?.ioValues[IOValueKeys.dischargeWaterPressure]?.value}
                />
              </PipePlaceholder>
            </PumpsRowCustom>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
      flex-direction: row;
      height: 100%;
      min-width: 175px;
    `,
    flexColumn: css`
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 100%;
      min-width: 175px;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

BaseAreaScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
};

BaseAreaScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(BaseAreaScreen);
