import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';

import {
  Pump,
  Pond,
  ValveConnect,
  PipePlaceholder,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowMedium,
  PumpsRowExtraSmall,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Info,
  MultiInfo,
  Statistic,
  PLCStateRouter,
  PLCColorRouter,
  // MobileStatTable,
  LoadingIndicator,
  Alarms,
} from 'isno/lib/components/displays';

import {
  AlarmReset,
  VFDPumpControl,
  InfoButton,
  SequenceControl,
} from 'isno/lib/components/controls';

import PLC from 'isno/lib/static/images/PLC.png';
// import { EquipmentPropType } from 'isno/lib/components/prop-types/Equipment';
// import { LanguageContext } from 'isno/lib/components/language';
import { fetchLocation, setIOValue, fetchPLC } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Building Data
  buildingTemp: 'building_temperature',
  // Water Flow Source
  cityTankMode: 'city_tank_mode',
  // Water Line Data
  dischargeWaterPressure: 'discharge_water_pressure',
  dischargeWaterTemperature: 'discharge_water_temperature',
  line3WaterFlow: 'line_3_water_flow',
  line2WaterFlow: 'line_2_water_flow',
  // Air Line Data
  line2AirFlow: 'line_2_air_flow',
  line2AirTemp: 'line_2_air_temperature',
  line3AirFlow: 'line_3_air_flow',
  line3AirTemp: 'line_3_air_temperature',
  dischargeAirPressure: 'discharge_air_pressure',
  // Pump Data
  speed: 'speed',
  dischargePressure: 'discharge_water_pressure',
  dischargeTemperature: 'discharge_water_temperature',
  amps: 'current',
  hours: 'run_time',
  sealTemp: 'seal_temperature',
  // Pump Alarms
  warning: 'warning',
  fault: 'fault',
  lowAmps: 'low_amperage',
  masterAlarm: 'master_alarm',
  lowSuctionPressure: 'low_suction_pressure',
  emergencyStop: 'emergency_stop',
  // Valve Control
  openClose: 'open_close',
  wellLevel: 'well_level',
  ib1: 'ib1_open_close',
  ib2: 'ib2_open_close',
  ib3: 'ib3_open_close',
  ib4: 'ib4_open_close',
  electronicFlow: 'electronic_water_flow',
  mechanicalFlow: 'mechanical_water_flow',
  riverLevel: 'river_flow',
  // Valve Alarms
  // v1OpeningError: 'V1_opening_error',
  // v1ClosingError: 'V1_closing_error',
};

function TransferScreen(props) {
  const theme = useTheme();
  // const language = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(15),
        props.fetchLocation(16), // (3)
        props.fetchPLC(26),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) {
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(15);
    props.fetchLocation(16); // (3)
    props.fetchPLC(26);
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null;
    };
  }, []);

  // console.log(props);
  const locationEquipment3 = props.locations?.locations?.[16]?.equipment; // (3)
  if (!props.locations?.locations?.[16]) { // (3)
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }
  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer flex="3" border backgroundColor={theme.backgroundColor} label="Transfer System">
            <InfoControlRow>
              <VFDPumpControl
                title="TP1 PID"
                autoPrecision={1}
                manPrecision={1}
                settings
                pumpData={{ id: `pump_VFD_${locationEquipment3?.['TP1 VFD']?.id}` }}
                pidData={locationEquipment3?.['TP1 VFD']}
                setIOValue={props.setIOValue}
                IOValueKeys={{
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                  pGainSetpoint: 'pgain_setpoint',
                  iGainSetpoint: 'igain_setpoint',
                }}
                iGainPrecision={2}
                pGainPrecision={2}
                pGainMax="32767"
                iGainMax="32767"
                pGainMin="1"
                iGainMin="1"
                writeValues={[0, 1]}
                readValues={[0, 1]}
                max="1500"
              />
              <MultiInfo
                title="System Water Flows"
                subtitles={['Electronic', 'Mechanical']}
                statistics={[
                  locationEquipment3?.Other?.ioValues[IOValueKeys.electronicFlow]?.value,
                  locationEquipment3?.Other?.ioValues[IOValueKeys.mechanicalFlow]?.value,
                ]}
                labels={[
                  locationEquipment3?.Other?.ioValues[IOValueKeys.electronicFlow]?.ioValueType?.units,
                  locationEquipment3?.Other?.ioValues[IOValueKeys.mechanicalFlow]?.ioValueType?.units,
                ]}
                ids={[
                  locationEquipment3?.Other?.ioValues[IOValueKeys.electronicFlow]?.id,
                  locationEquipment3?.Other?.ioValues[IOValueKeys.mechanicalFlow]?.id,
                ]}
              />
              <InfoButton
                title="Electronic Trip Vol."
                statistic={locationEquipment3?.['TF Electronic Flow Totalizer']?.ioValues?.trip_volume?.value}
                label={locationEquipment3?.['TF Electronic Flow Totalizer']?.ioValues?.trip_volume?.ioValueType?.units}
                precision={0}
                buttonWriteId={locationEquipment3?.['TF Electronic Flow Totalizer']?.ioValues?.trip_volume_reset?.id}
                buttonText="Reset"
                setIOValue={props.setIOValue}
              />
              <InfoButton
                title="Electronic Total Vol."
                statistic={locationEquipment3?.['TF Electronic Flow Totalizer']?.ioValues?.total_volume?.value}
                label={locationEquipment3?.['TF Electronic Flow Totalizer']?.ioValues?.total_volume?.ioValueType?.units}
                precision={0}
                buttonWriteId={locationEquipment3?.['TF Electronic Flow Totalizer']?.ioValues?.total_volume_reset?.id}
                buttonText="Reset"
                setIOValue={props.setIOValue}
              />
              <InfoButton
                title="Mechanical Trip Vol."
                statistic={locationEquipment3?.['TF Mechanical Flow Totalizer']?.ioValues?.trip_volume?.value}
                label={locationEquipment3?.['TF Mechanical Flow Totalizer']?.ioValues?.trip_volume?.ioValueType?.units}
                precision={0}
                buttonWriteId={locationEquipment3?.['TF Mechanical Flow Totalizer']?.ioValues?.trip_volume_reset?.id}
                buttonText="Reset"
                setIOValue={props.setIOValue}
              />
              <InfoButton
                title="Mechanical Total Vol."
                statistic={locationEquipment3?.['TF Mechanical Flow Totalizer']?.ioValues?.total_volume?.value}
                label={locationEquipment3?.['TF Mechanical Flow Totalizer']?.ioValues?.total_volume?.ioValueType?.units}
                precision={0}
                buttonWriteId={locationEquipment3?.['TF Mechanical Flow Totalizer']?.ioValues?.total_volume_reset?.id}
                buttonText="Reset"
                setIOValue={props.setIOValue}
              />
              {/* <Info
                title="River Level"
                statistic={locationEquipment3?.Other?.ioValues[IOValueKeys.riverLevel]?.value}
                label={locationEquipment3?.Other?.ioValues[IOValueKeys.riverLevel]?.ioValueType?.units}
              /> */}
              <SequenceControl
                title="Sequencer"
                pumpsHeight="432px"
                pumpsWidth="193px"
                setIOValue={props.setIOValue}
                sequencerData={locationEquipment3?.Sequencer}
                id={locationEquipment3?.Sequencer?.id}
                readValues={[1, 0]}
                writeValues={[1, 0]}
                pumps={[
                  locationEquipment3?.TP2,
                  locationEquipment3?.TP3,
                ]}
                disableEnable={[
                  locationEquipment3?.Sequencer?.ioValues?.p2_disable_enable,
                  locationEquipment3?.Sequencer?.ioValues?.p3_disable_enable,
                ]}
                setpoints={[
                  locationEquipment3?.Sequencer?.ioValues?.p2_sequence_order_setpoint,
                  locationEquipment3?.Sequencer?.ioValues?.p3_sequence_order_setpoint,
                ]}
                startParam={[
                  locationEquipment3?.Sequencer?.ioValues?.start_fast_offset_setpoint,
                  locationEquipment3?.Sequencer?.ioValues?.start_fast_timer_setpoint,
                  locationEquipment3?.Sequencer?.ioValues?.start_slow_offset_setpoint,
                  locationEquipment3?.Sequencer?.ioValues?.start_slow_timer_setpoint,
                ]}
                startValues={[
                  locationEquipment3?.Sequencer?.ioValues?.start_fast_offset,
                  locationEquipment3?.Sequencer?.ioValues?.start_fast_timer,
                  locationEquipment3?.Sequencer?.ioValues?.start_slow_offset,
                  locationEquipment3?.Sequencer?.ioValues?.start_slow_timer,
                ]}
                startLabels={[
                  'Fast Offset',
                  'Fast Timer',
                  'Slow Offset',
                  'Slow Timer',
                ]}
                stopParam={[
                  locationEquipment3?.Sequencer?.ioValues?.stop_fast_offset_setpoint,
                  locationEquipment3?.Sequencer?.ioValues?.stop_fast_timer_setpoint,
                  locationEquipment3?.Sequencer?.ioValues?.stop_fast_seal_setpoint,
                  locationEquipment3?.Sequencer?.ioValues?.stop_fast_speed_setpoint,
                  locationEquipment3?.Sequencer?.ioValues?.stop_slow_offset_setpoint,
                  locationEquipment3?.Sequencer?.ioValues?.stop_slow_timer_setpoint,
                  locationEquipment3?.Sequencer?.ioValues?.stop_slow_seal_setpoint,
                  locationEquipment3?.Sequencer?.ioValues?.stop_slow_speed_setpoint,
                ]}
                stopValues={[
                  locationEquipment3?.Sequencer?.ioValues?.stop_fast_offset,
                  locationEquipment3?.Sequencer?.ioValues?.stop_fast_timer,
                  null,
                  null,
                  locationEquipment3?.Sequencer?.ioValues?.stop_slow_offset,
                  locationEquipment3?.Sequencer?.ioValues?.stop_slow_timer,
                  null,
                  null,
                ]}
                stopLabels={[
                  'Fast Offset',
                  'Fast Timer',
                  'Fast Seal',
                  'Fast Speed',
                  'Slow Offset',
                  'Slow Timer',
                  'Slow Seal',
                  'Slow Speed',
                ]}
              />
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[26])}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[26], theme)}
              />
              <Alarms
                title="General Alarms"
                ioValues={locationEquipment3?.Other?.ioValues}
              />
              <AlarmReset
                title="System reset"
                buttonText="Reset"
                alarmData={locationEquipment3?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarm: 'master_alarm',
                  masterAlarmReset: 'alarm_reset',
                }}
              />
              {/* <VFDPumpControl
                title="Well Level PID"
                autoPrecision={1}
                manPrecision={1}
                pumpData={{ id: `Well_Info_${locationEquipment3?.Other?.ioValues?.[IOValueKeys.wellLevel]?.id}` }}
                pidData={locationEquipment3?.['Well PID']}
                setIOValue={props.setIOValue}
                IOValueKeys={{
                  autoMode: 'manual_auto',
                  manualMode: 'manual_auto',
                  autoManual: 'manual_auto',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                }}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                max="20"
              /> */}
            </InfoControlRow>
            <PumpsRowExtraSmall styles={styles.hiddenMobile}>
              <PipePlaceholder hidden />
              <PipePlaceholder hidden />
              <PipePlaceholder hidden />
              <PipePlaceholder pipeAbove="right" />
              <PipePlaceholder capAfter capLabel="To River PH / Connector Pond" capLabelTop="20px" capLabelLeft="-210px" horizontalAbove color={theme.pipeColors.water}>
                <Statistic
                  label={locationEquipment3?.Other?.ioValues[IOValueKeys.dischargeWaterPressure]?.ioValueType?.units}
                  border
                  statistic={locationEquipment3?.Other?.ioValues[IOValueKeys.dischargeWaterPressure]?.value}
                />
                <Statistic
                  precision={1}
                  label={locationEquipment3?.Other?.ioValues[IOValueKeys.dischargeWaterTemperature]?.ioValueType?.units}
                  border
                  statistic={locationEquipment3?.Other?.ioValues[IOValueKeys.dischargeWaterTemperature]?.value}
                />
              </PipePlaceholder>
            </PumpsRowExtraSmall>
            <PumpsRowMedium>
              <PipePlaceholder hidden />
              <Pump
                percent
                pipeAbove="right"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment3?.TP1}
                otherData={locationEquipment3?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                  speed: 'speed',
                }}
                alarms={[
                  locationEquipment3?.TP1?.ioValues?.high_seal_temperature,
                  locationEquipment3?.TP1?.ioValues?.low_amperage,
                  locationEquipment3?.TP1?.ioValues?.fault,
                ]}
                stats={[
                  {
                    stat: locationEquipment3?.TP1?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment3?.TP1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment3?.TP1?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment3?.TP1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment3?.TP1?.ioValues?.[IOValueKeys.sealTemp]?.value,
                    label: locationEquipment3?.TP1?.ioValues?.[IOValueKeys.sealTemp]?.ioValueType?.units,
                    precision: 1,
                  },
                ]}
              />
              <Pump
                percent
                pipeAbove="middle"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment3?.TP2}
                otherData={locationEquipment3?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                }}
                alarms={[
                  locationEquipment3?.TP2?.ioValues[IOValueKeys.fault],
                  locationEquipment3?.TP2?.ioValues[IOValueKeys.lowAmps],
                ]}
                stats={[
                  {
                    stat: locationEquipment3?.TP2?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment3?.TP2?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment3?.TP2?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment3?.TP2?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                percent
                pipeAbove="left"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment3?.TP3}
                otherData={locationEquipment3?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                }}
                alarms={[
                  locationEquipment3?.TP3?.ioValues[IOValueKeys.fault],
                  locationEquipment3?.TP3?.ioValues[IOValueKeys.lowAmps],
                ]}
                stats={[
                  {
                    stat: locationEquipment3?.TP3?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment3?.TP3?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment3?.TP3?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment3?.TP3?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
            </PumpsRowMedium>
            <PumpsRowExtraSmall>
              <Pond
                title="Infiltration Bed"
                color={theme.pipeColors.water}
                id={`Well_Info_${locationEquipment3?.Other?.ioValues?.[IOValueKeys.wellLevel]?.id}`}
              // highlightColor="#00ffffff"
                // alertText={locationEquipment3?.Other?.ioValues?.all_infiltration_beds_closed?.value ? 'All Infiltration Beds Closed' : null}
                // alertTop="76px"
                // alertLeft=" px"
              >
                <Info
                  title="Well Level"
                  precision={2}
                  statistic={locationEquipment3?.Other?.ioValues?.[IOValueKeys.wellLevel]?.value}
                  label={locationEquipment3?.Other?.ioValues?.[IOValueKeys.wellLevel]?.ioValueType?.units}
                  // alertText={(() => {
                  //   if (locationEquipment3?.Other?.ioValues?.low_well_level?.value) {
                  //     return 'Low Well Level';
                  //   } else if (locationEquipment3?.Other?.ioValues?.low_well_level_warning?.value) {
                  //     return 'Low Well Level Warning';
                  //   } else {
                  //     return null;
                  //   }
                  // })()}
                  // alertTop="-23px"
                  // alertLeft={locationEquipment3?.Other?.ioValues?.low_well_level_warning?.value ? '-50px' : '-15px'}
                />
              </Pond>
            </PumpsRowExtraSmall>
            <PumpsRowExtraSmall>
              <PipePlaceholder hidden />
              <ValveConnect
                manual
                readOnly
                percent
                percentTop="50px"
                percentLeft="-15px"
                reverseValve
                nameTop="7px"
                nameLeft="-55px"
                valveStateKeys={{
                  0: 'Open',
                  1: 'Closed',
                }}
                valveStateColorKeys={{
                  0: theme.onGreen,
                  1: theme.offRed,
                }}
                valveData={{ state: locationEquipment3?.Other?.ioValues?.[IOValueKeys.ib1]?.value, name: 'IB1' }}
              />
              <ValveConnect
                manual
                readOnly
                percent
                percentTop="50px"
                percentLeft="-15px"
                reverseValve
                nameTop="7px"
                nameLeft="-55px"
                valveStateKeys={{
                  0: 'Open',
                  1: 'Closed',
                }}
                valveStateColorKeys={{
                  0: theme.onGreen,
                  1: theme.offRed,
                }}
                valveData={{ state: locationEquipment3?.Other?.ioValues?.[IOValueKeys.ib2]?.value, name: 'IB2' }}
              />
              <ValveConnect
                manual
                readOnly
                percent
                percentTop="50px"
                percentLeft="-15px"
                reverseValve
                nameTop="7px"
                nameLeft="-55px"
                valveStateKeys={{
                  0: 'Open',
                  1: 'Closed',
                }}
                valveStateColorKeys={{
                  0: theme.onGreen,
                  1: theme.offRed,
                }}
                valveData={{ state: locationEquipment3?.Other?.ioValues?.[IOValueKeys.ib3]?.value, name: 'IB3' }}
              />
              <ValveConnect
                manual
                readOnly
                percent
                percentTop="50px"
                percentLeft="-15px"
                reverseValve
                nameTop="7px"
                nameLeft="-55px"
                valveStateKeys={{
                  0: 'Open',
                  1: 'Closed',
                }}
                valveStateColorKeys={{
                  0: theme.onGreen,
                  1: theme.offRed,
                }}
                valveData={{ state: locationEquipment3?.Other?.ioValues?.[IOValueKeys.ib4]?.value, name: 'IB4' }}
              />
            </PumpsRowExtraSmall>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

TransferScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  fetchPLC: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
};

TransferScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLC })(TransferScreen);
