import React, { useContext, useState } from 'react';
import { css, useTheme } from '@emotion/react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { FaDownload } from 'react-icons/fa';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { LoadingIndicator } from 'isno/lib/components/displays';
import { Button } from 'isno/lib/components/controls';
import { sendReportRequest } from '../actions';

function ReportScreen(props) {
  const theme = useTheme();
  const styles = stylesFromTheme(theme);
  const { language } = useContext(LanguageContext);
  const [report, setReport] = useState(0);
  const [response, setResponse] = useState(null);
  const [startDay, setStartDay] = useState(null);
  const [endDay, setEndDay] = useState(null);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  // const reportOptions = [];

  // const generateReportOptions = (reports) => {
  //   let counter = 0;
  //   reports.forEach((report) => {
  //     reportOptions.push({ value: counter, label: report.name });
  //     counter++;
  //   });
  //   return reportOptions;
  // };

  // Should move to a report generator like above.
  const reportOptions = [ // leave unused values in because they are for other mountains
    { value: 0, label: language.trailReport },
    { value: 1, label: language.snowmakingReport },
    { value: 2, label: language.notesReport },
    { value: 3, label: language.energyReport },
  ];

  const reportSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused || state.menuIsOpen ? theme.borderColorLight : theme.borderColorExtraLight,
      ':hover': {
        borderColor: theme.borderColorLight,
      },
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
    }),
    option: (provided, state) => ({
      ...provided,
      color: theme.textColor,
      cursor: 'pointer',
    }),
    menuList: (provided, state) => ({
      ...provided,
      zIndex: 2,
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 2,
    }),
  };

  return (
    <div css={styles.background}>
      <LoadingIndicator visible={loading} text={language.initDownload} />
      <h1>{language.reports}</h1>
      <div css={[styles.input, styles.select]}>
        <Select
          options={reportOptions}
          styles={reportSelectStyles}
          defaultValue={{ value: 0, label: language.trailReport }}
          onChange={(selection) => { setReport(selection.value); }}
          theme={theme.selectTheme}
        />
      </div>
      <div css={[styles.input, styles.calendar]}>
        <div css={css`margin: 10px 5px 0px 5px; display: flex;`}>
          <MobileDateTimePicker
            label={language.startDate}
            ampm={false}
            showTodayButton
            inputVariant="outlined"
            value={startDay}
            onChange={setStartDay}
          />
          <div css={css`width: 5px;`} />
          <MobileDateTimePicker
            label={language.endDate}
            ampm={false}
            showTodayButton
            inputVariant="outlined"
            value={endDay}
            onChange={setEndDay}
          />
        </div>
      </div>
      <button
        type="button"
        id="menu"
        css={styles.menu}
        onClick={async () => {
          if (startDay === null || endDay === null) {
            setShowError(true);
          } else {
            setLoading(true);
            setShowError(false);
            // console.log(startDay.format());
            // console.log(endDay.format());
            // console.log(startDay);
            setResponse(await sendReportRequest(startDay.format(), endDay.format(), report, startDay));
            setTimeout(() => {
              setLoading(false);
            }, 1500);
          }
        }}
      ><div>{language.download}</div>
        <div css={css`height: 100%`}>
          <FaDownload
            css={styles.menuDark}
            size={18}
          />
        </div>
      </button>
      <div css={[styles.input, styles.error, showError ? {} : styles.hidden]}>
        {language.selectStartEndDate}
      </div>
      {(response && response !== 200) && (
        <div css={styles.confirmWrapper}>
          <div css={[styles.confirmContainer]}>
            <p css={css`text-color: red; margin-top: 10px;`}>{language.error}</p>
            { response === 200 && (
            <div css={css`padding: 10px; font-size: 1.3em;`}>
              <p>{language.timeout}</p>
            </div>
            )}
            { response === 404 && (
            <div css={css`padding: 10px; font-size: 1.3em;`}>
              <p>{language.notFound}</p>
            </div>
            )}
            { response === 400 && (
            <div css={css`padding: 10px; font-size: 1.3em;`}>
              <p>{language.badRequest}</p>
            </div>
            )}
            <div css={css`display: flex; flex-direction: row;`}>
              <Button
                dot={false}
                outline
                width="58px"
                textColor={theme.textColor}
                borderColor="#9e9e9e"
                backgroundColor={theme.backgroundColor}
                // onClick={async () => {
                //   await props.reset2FA(props.settings?.settings?.userId);
                // }}
                onClick={() => {
                  setResponse(null);
                }}
              >
                <div css={css`display: flex;`}>
                  <p>{language.ok}</p>
                </div>
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const stylesFromTheme = (theme) => {
  return {
    background: css`
      color: ${theme.inputTextColorLight};
      padding: 14px;
      border-radius: 4px;
      position: relative;
      height: 100vh;
      height: 100dvh;
      width: 100%;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        margin-left: 20px;
      }
    `,
    input: css`
      margin-top: 10px;
      color: ${theme.selectDropdownText};
    `,
    hidden: css`
      display: none;
    `,
    error: css`
      background-color: rgba(94, 236, 125, 0.5);
      padding: 10px;
      border-radius: 5px;
    `,
    select: css`
      margin-left: 5px;
      width: 200px;
    `,
    calendar: css`
      max-width: 400px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        width: 100%;
      }
    `,
    menu: css`
      margin-left: 5px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: white;
      background: ${theme.linkColors.reports};
      height: 35px;
      overflow: hidden;
      border-radius: 5px;
      padding-left: 20px;
      margin-top: 10px;
      font-weight: bold;
      :hover {
        box-shadow: 0 0 8px ${theme.shadow};
      }
      :active {
        color: black;
        box-shadow: 0 0 8px black;
      }
      :focus {
        outline: 0;
      }
    `,
    confirmWrapper: css`
    backdrop-filter: blur(1px);
    width: calc(100% - 168px); 
    height: 100%;
    margin-left: 8px;
    padding: 250px 0px 0px calc(50% - 175px);
    position: absolute;
    top: 0px;
    z-index: 999;
    cursor: wait;
    @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
      padding: 250px 0px 0px calc(50% - 90px);
    }
    `,
    confirmContainer: css`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 160px;
    height: 135px;
    margin: 4px;
    cursor: auto;
    background-color: ${theme.containerBackgroundColor};
    border: 1px solid ${theme.borderColor};
    border-radius: 4px;
  `,
    menuDark: css`
      height: 100%;
      background: ${theme.linkColors.darkAccent};
      padding: 10px;
      width: 35px;
      margin-left: 20px;
    `,
    dateRangePicker: css`
      height: 400px;
    `,
  };
};

export default ReportScreen;
